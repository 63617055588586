<template>
    <div class="flex h-screen flex-col items-center justify-center">
        <h1 class="mb-2 text-2xl">
            {{ title }}
        </h1>
        <div class="mb-4 text-base">
            {{ description }}
        </div>
        <AspectButton v-if="status === 403" @click="onLogout">
            {{ t('Logout') }}
        </AspectButton>
    </div>
</template>

<script lang="ts" setup>
    import { route } from 'ziggy';

    import { t } from '@aspect/shared/plugins/i18n.ts';
    import useHttpStatus from '@aspect/shared/composables/use-http-status.ts';

    import AspectButton from '@aspect/shared/components/aspect-button.vue';

    defineOptions({
        layout: '',
    });

    const props = defineProps<{
        status: 503 | 500 | 404 | 403;
        message?: string;
    }>();

    const { getTitle, getDescription } = useHttpStatus();

    const title = computed(() => getTitle(props.status));
    const description = computed(() => {
        const defaultMessages = [
            'This action is unauthorized.',
        ];

        if (props.message && !defaultMessages.includes(props.message)) {
            return props.message;
        }

        return getDescription(props.status);
    });

    function onLogout() {
        router.post(route('logout'));
    }
</script>
